import 'bootstrap/dist/css/bootstrap.min.css';

import '../css/App.css'; // this needs to be below the bootstrap import to overwrite styles

import { Routes, Route } from "react-router-dom";


import Button from 'react-bootstrap/Button';

import 'react-dropdown/style.css';

import {useState, useEffect} from 'react';

import { ethers } from 'ethers';

const range = (start, stop, step=1) => Array.from({ length: (stop - start) / step + 1}, (_, i) => start + (i * step));

function App() {
    return (
        <div className="App">
            <Routes>
                <Route path="/bricks" element={<BrickBreakersSplitterPage contractName="bricks"/>} />
                <Route path="/gm420" element={<BrickBreakersSplitterPage contractName="gm420"/>} />
            </Routes>
        </div>
    );
}

function BrickBreakersSplitterPage({ contractName }) {
    // change before prod
    const PROD_CONTRACT_ADDRESSES = {
        bricks: "0x843dfC05eF59d99849D658AF6dF629a28bcBe401",
        gm420: "0x8bEB4c717bC6519cb88a013D6702c009971F3874",
    };
    const PAYEES_PER_CONTRACT = {
        bricks: 4,
        gm420: 4,
    };

    const ADDRESSES = [
        '0xd8CECd8ec328188AD607E5bCE34e24160092E011',
        '0x4fD907a35D36dcAe52af7317068794791F8e9225',
        '0xA422bfFF5dABa6eeeFAFf84Debf609Edf0868C5f',
        '0xcF55ECB10CAB5b817d6382046DD5F78Ff568a878',
    ]

    const [addresses, setAddresses] = useState([]);
    const [detailsPerAddress, setDetailsPerAddress] = useState({});
    const [totalReleases, setTotalReleases] = useState(0);
    const [totalShares, setTotalShares] = useState(0);
    const [balance, setBalance] = useState(null);


    const [currentEthAccount, setCurrentEthAccount] = useState(null);

    const ethProvider = window.ethereum && new ethers.providers.Web3Provider(window.ethereum);


    useEffect(async () => {
        if (!ethProvider) {
            return;
        }

        if (currentEthAccount) {
            return;
        }

        const getAccountIfPossible = async () => {
            const accounts = await ethProvider.listAccounts();
            return accounts.length > 0 ? accounts[0] : null;
        }

        let account = await getAccountIfPossible();
        if (account !== null) {
            setCurrentEthAccount(account);
        }
    })

    const connectToWallet = async () => {
        if (!window.ethereum) {
            console.log("Error: MetaMask extension missing, please install to proceed....");
            return;
        }

        const ethAccounts = await window.ethereum.request({method: "eth_requestAccounts"});
        setCurrentEthAccount(ethAccounts[0]);
    }

    const getContractSigner = () => {
        const PROD_CONTRACT_ABI = require("./contract_abi.json");

        const signer = ethProvider.getSigner();
        return new ethers.Contract(PROD_CONTRACT_ADDRESSES[contractName],
            PROD_CONTRACT_ABI, signer);
    }

    const releaseToWallet = async (address) => {
        const contract = getContractSigner();

        try {
            // calculate final mint value
            let gasLimit = (await contract.estimateGas.release(address)).mul('120').div('100');
            await contract.release(address, {gasLimit: gasLimit});
        } catch (err) {
            console.log("Contract error: " + err.error.message);
        }
    }

    const fetchContractDetails = async () => {
        const contract = getContractSigner();

        const addrs = await Promise.all(range(0, PAYEES_PER_CONTRACT[contractName] - 1).map((i) => contract.payee(i)));
        setAddresses(addrs);
        const released = await Promise.all(addrs.map(x => contract.released(x)));
        const shares = await Promise.all(addrs.map(x => contract.shares(x)));
        const [_totalReleased, _totalShares, _balance] = await Promise.all([contract.totalReleased(), contract.totalShares(), ethProvider.getBalance(PROD_CONTRACT_ADDRESSES[contractName])]);
        let result = {}
        for (let i = 0; i < addrs.length; i++) {
            result[addrs[i]] = {
                address: addrs[i],
                shares: shares[i],
                released: released[i],
            }
        }
        setDetailsPerAddress(result);
        setTotalReleases(_totalReleased);
        setTotalShares(_totalShares);
        setBalance(_balance);
    }

    useEffect(async () => {
        if (currentEthAccount) {
            await fetchContractDetails();
        }
    }, [currentEthAccount]);

    const remainingPerAddress = (address) => {
        return ethers.utils.formatEther(balance.add(totalReleases).div(totalShares).mul(detailsPerAddress[address].shares).sub(detailsPerAddress[address].released)).toString();
    }

    if (!currentEthAccount) {
        return (<div id="primary_sale_controls"><Button variant="primary" size="lg" onClick={connectToWallet}>Connect MetaMask Wallet</Button></div>);
    }
    if (!balance) {
        return null;
    }

    return (
        <div id="primary_sale_controls">
            <table>
                <thead>
                <tr>
                    <th>Shares</th>
                    <th>Payment</th>
                    <th>Address</th>
                    <th>Release</th>
                </tr>
                </thead>
                <tbody>
                {
                    addresses.map((address, index) => {
                        return <tr key={address}>
                            <td>{detailsPerAddress[address].shares.toString()}</td>
                            <td>{remainingPerAddress(address)}</td>
                            <td>{address.toString()}</td>
                            <td><Button onClick={() => releaseToWallet(address)} className="fundButton">Release!</Button></td>
                        </tr>;
                    })
                }
                </tbody>
            </table>
        </div>
    );
}

export default App;
